<template>
  <div>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="第一步:打包信息" name="first">
        <div style="margin-left: 6%">
          <div class="newForm">
            <el-button style="margin: 10px" class="seniorbut" @click="onSubmit"
              >下一步</el-button
            >
            <el-form
              ref="packageForm"
              :model="packageForm"
              :rules="packageForm"
              label-width="100px"
            >
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="打包批次号:">
                    <el-input
                      v-model="huowuinfo.billPackNumber"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="总件数:">
                    <el-input
                      v-model="huowuinfo.sumCount"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="运输方式:" prop="transportName">
                    <el-select
                      v-model="packageForm.channelTransportId"
                      clearable
                      filterable
                      @change="selectTId"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in transportList"
                        :label="item.transportName"
                        :key="index"
                        :value="item.channelTransportId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="总重量:">
                    <el-input
                      v-model="huowuinfo.sumWeight"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>

              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="出货渠道:" prop="channelId">
                    <el-select
                      v-model="packageForm.channelId"
                      clearable
                      placeholder="请选择出货渠道"
                    >
                      <el-option
                        v-for="(item, index) in channelList"
                        :label="item.channelName"
                        :key="index"
                        :value="item.channelId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="总体积:">
                    <el-input
                      v-model="huowuinfo.volume"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="渠道代理:" prop="agentId">
                    <el-select
                      v-model="packageForm.customerAgentId"
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="(item, index) in agentIdArr"
                        :label="item.agentName"
                        :key="index"
                        :value="item.customerAgentId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="收货人:" prop="consigneeName">
                    <el-input v-model="packageForm.consigneeName"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="仓库:" prop="storageId">
                    <el-select
                      v-model="packageForm.storageId"
                      clearable
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in cangkuList"
                        :key="item.id"
                        :label="item.storageName"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
                <div class="queryItem1 ZD">
                  <el-form-item label="出库单号:">
                    <el-input
                      placeholder="请输入或生成"
                      v-model="packageForm.packageStockNumber"
                    ></el-input>
                  </el-form-item>
                  <el-button type="primary" @click="fillbut" size="mini"
                    >自动填写</el-button
                  >
                </div>
              </div>

              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="转单号:">
                    <el-input
                      clearable
                      placeholder="请输入"
                      v-model="packageForm.channelNumber"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="收货人电话:" prop="receivingPhone">
                    <el-input v-model="packageForm.receivingPhone"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="会员号:" prop="memberId">
                    <el-input
                      v-model="huowuinfo.memberId"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="国家/地区:" prop="chineseName">
                    <el-select
                      v-model="packageForm.areaCountryId"
                      clearable
                      filterable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in areaCountList"
                        :key="item.areaCountryId"
                        :label="item.showName"
                        :value="item.areaCountryId"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="会员名称:" prop="nickname">
                    <el-input
                      v-model="huowuinfo.nickname"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="州\省:" prop="provinceName">
                    <el-input v-model="packageForm.provinceName"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="inlineDiv">
                <div class="queryItem1">
                  <el-form-item label="团号:">
                    <el-input
                      v-model="packageForm.invitationCode"
                      :disabled="true"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="城市:" prop="cityName">
                    <el-input v-model="packageForm.cityName"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="queryItem1">
                <el-form-item label="详细地址:" prop="addressee">
                  <el-input
                    v-model="packageForm.addressee"
                    placeholder="请输入地址"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div class="contentB">
            <div class="searchNum">
              <div>打包数量：</div>
              <el-input placeholder="请输入内容" v-model="InputNum"></el-input>
              <el-button
                type="primary"
                style="margin-left: 10px"
                @click="packageNumClick"
                size="small"
                >确 定
              </el-button>
            </div>
            <div
              class="packageBox"
              v-for="(item, index) in packageForm.stockPackDescVo"
              :key="index"
            >
              <div class="packageItem">
                <div style="padding: 6px">{{ index + 1 }}号包</div>
                <div style="margin-bottom: 6px">
                  <el-input
                    v-model="item.weight"
                    type="number"
                    placeholder="重量(kg)"
                  ></el-input>
                </div>
                <div>
                  <el-input
                    v-model="item.length"
                    placeholder="长（cm）"
                    type="number"
                  ></el-input>
                  <el-input
                    v-model="item.width"
                    placeholder="宽（cm）"
                    type="number"
                  ></el-input>
                  <el-input
                    v-model="item.height"
                    placeholder="高（cm）"
                    type="number"
                  ></el-input>
                </div>
              </div>
              <div class="shangchunbox" @click="clickUoload(index)">
                <el-upload
                  class="upload-demo"
                  :action="activeUrl"
                  list-type="picture-card"
                  :accept="'image/*'"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :http-request="uploadSectionFile"
                  :file-list="item.fileList"
                  :class="{ hide: hideUpload }"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <div class="el-upload__text">点击上传图片</div>

                <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="dialogImageUrl" alt="" />
                </el-dialog>
              </div>
              <div class="butboxs" v-if="index == 0">
                <el-button @click="addbut">添加</el-button>
              </div>
              <div class="butboxs" v-else>
                <el-button @click="deletebut(index)">删除</el-button>
              </div>
            </div>
          </div>
          <div class="remarks">
            <div>打包备注：</div>
            <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="packageForm.comment"
              show-word-limit
            >
            </el-input>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="第二步:包装服务" name="second">
        <div style="margin-left: 6%">
          <div class="newForm">
            <el-button class="seniorbut" @click="packStorage">保存</el-button>
            <div v-show="feixiangList.length > 0" class="topbox">
              <el-checkbox-group v-model="qudaoidlist">
                <div
                  class="checkboxdiv"
                  v-for="(item, index) in feixiangList"
                  :key="index"
                >
                  <el-checkbox style="width: auto" :label="item.itemServiceId"
                    >{{ item.title }}
                  </el-checkbox>
                  <div class="countBox displayFlex">
                    <div>数量:</div>
                    <el-input
                      style="
                        width: 80px;
                        border: 1px solid #d7d7d7;
                        margin-left: 4px;
                      "
                      v-model="item.count"
                    ></el-input>
                  </div>
                </div>
              </el-checkbox-group>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      title="会员管理"
      :visible.sync="isDT"
      width="50%"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <div class="newForm">
        <div class="formS">
          <el-form
            ref="vf"
            size="mini"
            :model="vf"
            :rules="vfr"
            label-width="80px"
          >
            <el-row>
              <el-col :span="19" style="display: flex">
                <div class="queryItem1">
                  <el-form-item label="会员号:" prop="memberId">
                    <el-input v-model="vf.memberId"></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="会员名称:" prop="nickname">
                    <el-input v-model="vf.nickname"></el-input>
                  </el-form-item>
                </div>
                <div class="queryItem1">
                  <el-form-item label="仓库:" prop="memberId">
                    <el-select
                      v-model="vf.memberId"
                      placeholder="请选择活动区域"
                    >
                      <el-option label="区域一" value="shanghai"></el-option>
                      <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :span="1">
                <el-form-item label-width="10px">
                  <el-button type="warning" @click="cxEdit">查 询</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="tableBox">
          <el-table
            :data="ut"
            size="mini"
            max-height="400px"
            border
            style="width: 100%"
          >
            <el-table-column
              type="index"
              label="序号"
              align="center"
              width="50"
            >
            </el-table-column>
            <el-table-column
              prop="memberId"
              label="会员号"
              align="center"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="nickName"
              label="会员名称"
              align="center"
              min-width="120"
            >
            </el-table-column>

            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              min-width="100"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  style="color: #ef7c1b"
                  size="mini"
                  @click="xz(scope.row)"
                  >选 择
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页组件 -->
          <paging
            ref="pags"
            :pageNum="pageNum"
            :total="total"
            :sizeList="sizeList"
            :size="size"
            @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange"
          ></paging>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
export default {
  inject: ["reload"],
  data() {
    return {
      huowuinfo: {}, //详情显示字段
      qudaoidlist: [], //渠道id列表
      itemServiceVoList: [], //渠道id列表
      activeName: "first",
      packageForm: {
        addressee: "",
        areaCountryId: "",
        billPackId: "",
        channelId: "",
        channelName: "",
        channelNumber: "",
        packageStockNumber: "",
        channelTransportId: "",
        cityName: "",
        comment: "",
        consigneeName: "",
        customerAgentId: "",
        expressName: "",
        itemServices: [
          {
            itemServiceId: "",
            itemServiceTitle: "",
            unitPrice: "",
            count: "",
          },
        ],
        stockPackDescVo: [
          {
            fileName: "",
            height: "",
            length: "",
            serialNumber: 1,
            weight: "",
            width: "",
            fileList: [],
          },
        ],
        postCode: "",
        provinceName: "",
        receivingPhone: "",
        storageId: "",
        transportName: "",
      },

      checkList: [],
      checkArr: [],
      //    图片上传
      //  上传配置
      activeUrl: "",
      dialogImageUrl: "",
      dialogVisible: false,
      hideUpload: false,
      areaCountList: [], //  国家地区下拉
      channelAndTransportList: [], //  渠道&运输方式下拉数据
      transportList: [], //  运输方式下拉菜单
      channelList: [], //  渠道方式下拉菜单
      agentIdArr: [], //  渠道代理
      cangkuList: [], //仓库列表
      feixiangList: [], //费项列表
      //  会员管理
      isDT: false,
      vf: {},
      vfr: {},
      ut: [],
      pageNum: 1,
      total: 0,
      size: 20,
      sizeList: [10, 20, 50, 100, 200],
      uploadIndex: 0, //当前上传图片的包裹下标
      pNum: 1,
      InputNum: 1,
    };
  },
  watch: {
    pNum: function (a, b) {
      this.changeNum();
      this.InputNum = a;
    },
    "packageForm.channelTransportId": function (a, b) {
      this.selectTId(a);
    },
    "packageForm.channelId"(a, b) {
      let nid = a;
      this.channelList.forEach((item) => {
        if (item.channelId == nid) {
          this.packageForm.channelName = item.channelName;
        }
      });
    },
  },
  created() {
    this.packageForm.billPackId = this.$route.query.id;
    let nid = this.$route.query.id;

    this.activeUrl = this.common.uploadUrl;
    this.getAreaContryList(); //  获取国家地区列表
    this.getStorageList(); //获取仓库列表
    this.getTransportMethodList(); //  获取运输方式列表
    this.getChannelAgentChooseList(); //  获取渠道代理
    // this.getItemServiceDaBaoList(); //获取打包服务费项列表

    // this.getStockPackDetail(nid); //获取详情
  },
  activated() {
    let nid = this.$route.query.id;
    this.activeName = "first";
    if (nid) {
      this.packageForm.billPackId = this.$route.query.id;
      this.getStockPackDetail(nid); //获取详情
    }
  },
  mounted() {},
  methods: {
    //自动填写
    fillbut() {
      Api.createChannelNumber().then((res) => {
        this.packageForm.packageStockNumber =
          res.data.result.channelNumber || this.packageForm.packageStockNumber;
      });
    },
    //添加按钮
    addbut() {
      let arrs =
        JSON.parse(JSON.stringify(this.packageForm.stockPackDescVo)) || [];
      if (arrs.length < 1) {
        return;
      }
      this.InputNum++;
      let lengths = arrs.length || 1;

      let obj = {
        fileName: "",
        height: "",
        length: "",
        serialNumber: "",
        weight: "",
        width: "",
        fileList: [],
      };
      arrs.push(obj);

      arrs.forEach((item, index) => {
        item.serialNumber = index + 1;
      });
      this.packageForm.stockPackDescVo = arrs;
    },
    //删除按钮
    deletebut(nid) {
      this.packageForm.stockPackDescVo.splice(nid, 1);
      this.InputNum = this.packageForm.stockPackDescVo.length;
    },
    //获取详情
    getStockPackDetail(nid) {
      Api.getStockPackDetail({ billPackId: nid }).then((res) => {
        let packageForm = res.data.result || [];
        this.huowuinfo = {
          //显示字段(仅显示)
          billPackNumber: packageForm.billPackNumber, //打包批次号
          sumCount: packageForm.sumCount, //总件数
          sumWeight: packageForm.sumWeight, //总重量
          volume: packageForm.volume, //总体积
          memberId: packageForm.memberId, //会员号
          nickname: packageForm.nickname, //会员名称
          invitationCode: packageForm.invitationCode, //团号
        };
        // fileList
        let pNum = 1;
        if (packageForm.stockPackDescVo) {
          pNum = packageForm.stockPackDescVo.length || 1;
          let fArr = [];
          packageForm.stockPackDescVo.forEach((item, index) => {
            if (item.url) {
              let objs = {
                name: item.fileName || "",
                url: item.url || "",
              };
              fArr.push(objs);
            }
            packageForm.stockPackDescVo[index].fileList = fArr;
          });
        }
        this.pNum = pNum;
        this.InputNum = pNum;

        this.packageForm = {
          areaCountryId: packageForm.areaCountryId || "",
          postCode: packageForm.postCode || "",
          expressName: "", //快递公司 无
          cityName: packageForm.cityName || "",
          comment: packageForm.comment || "",
          addressee: packageForm.addressee || "",
          billPackId: packageForm.billPackId || "",
          channelId: packageForm.channelId || "",
          channelName: packageForm.channelName || "",
          channelNumber: packageForm.channelNumber || "",
          channelTransportId: packageForm.channelTransportId || "",
          consigneeName: packageForm.consigneeName || "",
          customerAgentId: packageForm.agentId || "",
          itemServices: [],
          stockPackDescVo: packageForm.stockPackDescVo || [
            {
              fileName: "",
              height: "",
              length: "",
              serialNumber: 1,
              weight: "",
              width: "",
            },
          ],
          provinceName: packageForm.provinceName || "",
          receivingPhone: packageForm.receivingPhone || "",
          storageId: packageForm.storageId || this.cangkuList[0].id, //仓库id无
          transportName: packageForm.transportName || "",
          packageStockNumber: packageForm.packageStockNumber || "",
        };
        let arr2 = [];
        packageForm.itemServiceVo.forEach((item) => {
          arr2.push(item.id);
        });
        this.itemServiceVoList = packageForm.itemServiceVo || [];
        this.qudaoidlist = arr2;
        this.getItemServiceDaBaoList(); //获取打包服务费项列表
      });
    },
    //获取打包服务费项列表
    getItemServiceDaBaoList() {
      Api.getItemServiceDaBaoList().then((res) => {
        let feixiangList = res.data.result || [];
        feixiangList.forEach((item) => {
          item.count = item.count || 1;
        });
        for (var i = 0; i < this.itemServiceVoList.length; i++) {
          for (var a = 0; a < feixiangList.length; a++) {
            if (feixiangList[a].itemServiceId == this.itemServiceVoList[i].id) {
              feixiangList[a].count = this.itemServiceVoList[i].count;
            }
          }
        }
        this.feixiangList = feixiangList;
      });
    },
    //下一步
    onSubmit() {
      this.activeName = "second";
    },
    //获取仓库列表
    getStorageList() {
      Api.getStorageList().then((res) => {
        this.cangkuList = res.data.result || [];
        this.packageForm.storageId = this.cangkuList[0].id + "" || "";
        console.log(this.packageForm.storageId);
      });
    },
    // 获取国家地区列表
    getAreaContryList() {
      Api.getAreaContryList_q().then((res) => {
        let areaCountList = res.data.result || [];
        var showArr = [];
        //暂时不做中英文切换
        for (var i = 0; i < areaCountList.length; i++) {
          showArr.push({
            areaCountryId: areaCountList[i].areaCountryId,
            numberCode: areaCountList[i].numberCode,
            showName: areaCountList[i].chineseName,
          });
        }
        this.areaCountList = showArr;
      });
    },

    getTransportMethodList() {
      //  获取运输方式列表
      Api.channelAndTransportList().then((res) => {
        if (res.data.status === "success") {
          // let obj = {};
          // this.transportList = res.data.result.reduce((cur, next) => {
          //   //  去重
          //   obj[next.channelTransportId]? "" : (obj[next.channelTransportId] = true && cur.push(next));
          //   return cur;
          // }, []);
          let datas = res.data.result || [];
          this.channelAndTransportList = datas;

          let arrids = []; //运输方式id
          let arr2 = []; //运输方式列表
          for (var i = 0; i < datas.length; i++) {
            if (arrids.indexOf(datas[i].channelTransportId) == -1) {
              arrids.push(datas[i].channelTransportId);
              arr2.push(datas[i]);
            }
          }
          this.transportList = arr2;
        } else {
          this.$message.error(`${res.data.message}--渠道与运输方式下拉菜单`);
        }
      });
    },

    selectTId(val) {
      //  选择运输方式下拉框时筛选渠道数据
      // this.channelAndTransportList.forEach((item) => {
      //   if (item.channelTransportId === val) {
      //     arr.push(item);
      //   }
      // });
      let arr = [];
      let cids = [];
      this.channelAndTransportList.forEach((item) => {
        if (item.channelTransportId == val) {
          if (cids.indexOf(item.channelId) == -1) {
            cids.push(item.channelId);
            arr.push(item);
          }
        }
      });
      this.channelList = arr;
    },

    getChannelAgentChooseList() {
      //  获取渠道代理
      Api.channelAgentChooseList().then((res) => {
        if (res.data.status === "success") {
          this.agentIdArr = res.data.result || [];
        } else {
          this.$message.error(`${res.data.message}--渠道代理`);
        }
      });
    },

    changeVip() {
      //  选择会员名称
      this.getVipList();
      this.isDT = true;
    },

    getVipList() {
      //  获取会员列表
      this.vf.pageStart = this.pageNum;
      this.vf.pageTotal = this.size;
      Api.getMemberList(this.vf).then((res) => {
        if (res.data.status === "success") {
          this.ut = res.data.result.data;
          this.total = res.data.result.pageCount || 0;
        }
      });
    },

    xz(data) {
      //  会员表格选择事件
      this.packageForm.memberId = data.memberId;
      this.packageForm.nickname = data.nickName;
      this.isDT = false;
    },
    cxEdit() {
      //  查询按钮
      this.pageNum = 1;
      this.getVipList();
    },
    handleClose() {
      //  会员弹出框关闭前回调事件
      this.resetData("vf");
    },
    resetData(formName) {
      this.$refs[formName].resetFields();
    },
    handleSizeChange(val) {
      //  分页事件
      this.size = val;
      this.getVipList();
    },
    handleCurrentChange(val) {
      //  分页事件
      this.pageNum = val;
      this.getVipList();
    },
    handleRemove(file, fileList) {
      //  图片上传事件
      /* 移除图片 */
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    clickUoload(e) {
      this.uploadIndex = e;
    },
    uploadSectionFile(param) {
      // 上传图片
      const file = param.file;
      const fileName = file.name;
      const form = new FormData(); //  根据后台需求数据格式
      form.append("file", file); //  文件对象
      form.append("fileName", fileName); //  本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      Api.uploadFile(form).then((res) => {
        if (res.data.status === "success") {
          this.packageForm.stockPackDescVo[this.uploadIndex].fileName =
            res.data.result.fileName || "";
          this.packageForm.stockPackDescVo[this.uploadIndex].fileList.push({
            name: res.data.result.fileName,
            uid: res.data.result.redirectUrl,
            url: res.data.result.url,
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    packageNumClick() {
      //  打包数量确定按钮
      let num = JSON.parse(JSON.stringify(this.InputNum));
      this.pNum = num * 1;
    },

    // 打包确认
    packStorage() {
      let arr = [];
      this.qudaoidlist.forEach((item) => {
        let index = this.feixiangList.findIndex((e) => {
          return e.itemServiceId === item;
        });

        index != -1
          ? arr.push({
              itemServiceId: this.feixiangList[index].itemServiceId,
              itemServiceTitle: this.feixiangList[index].title,
              unitPrice: this.feixiangList[index].unitPrice,
              count: this.feixiangList[index].count,
            })
          : false;
      });
      this.packageForm.itemServices = arr;
      let newArrs = JSON.parse(
        JSON.stringify(this.packageForm.stockPackDescVo)
      );
      for (var a = 0; a < newArrs.length; a++) {
        delete newArrs[a].fileList;
      }
      let param = {
        addressee: this.packageForm.addressee,
        areaCountryId: this.packageForm.areaCountryId,
        billPackId: this.packageForm.billPackId,
        channelId: this.packageForm.channelId,
        channelName: this.packageForm.channelName,
        channelNumber: this.packageForm.channelNumber,
        channelTransportId: this.packageForm.channelTransportId,
        cityName: this.packageForm.cityName,
        comment: this.packageForm.comment,
        consigneeName: this.packageForm.consigneeName,
        customerAgentId: this.packageForm.customerAgentId,
        expressName: this.packageForm.expressName,
        itemServices: this.packageForm.itemServices,
        packageList: newArrs,
        postCode: this.packageForm.postCode,
        provinceName: this.packageForm.provinceName,
        receivingPhone: this.packageForm.receivingPhone,
        storageId: this.packageForm.storageId,
        transportName: this.packageForm.transportName,
        packageStockNumber: this.packageForm.packageStockNumber, //出库单号
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.packStorage(param)
        .then((res) => {
          if (res.data.status == "success") {
            this.$message.success("打包成功");
            setTimeout(() => {
              // tools.closePage();
              this.reload();
              this.activeName = "first";
            }, 500);
          }
        })
        .catch(() => {
          this.packageForm.itemServices = [];
        });
    },
    //改变打包数量
    changeNum() {
      var num = this.pNum || 1;
      if (num <= 1) {
        num = 1;
        this.pNum = 1;
        this.packageForm.stockPackDescVo = [
          {
            fileName: "", //文件名
            height: "", //高
            length: "", //长度
            serialNumber: 1, //序号
            weight: "", //重量
            width: "", //长度
            fileList: [],
          },
        ];
      } else {
        let arr = [];
        for (var i = 0; i < num; i++) {
          let obj = {
            fileName: "", //文件名
            height: "", //高
            length: "", //长度
            serialNumber: i + 1, //序号
            weight: "", //重量
            width: "", //长度
            fileList: [],
          };
          arr.push(obj);
        }
        this.packageForm.stockPackDescVo = arr;
      }
    },
    //下一步
    nextStep() {
      this.activeName = "second";
    },
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.searchNum {
  display: flex;
  align-items: center;
  margin: 20px 0 0px 10px;
  padding-bottom: 20px;
  color: #3a3a3a;
  border-bottom: 1px silver solid;
  font-size: 16px;
  /deep/ .el-input__inner,
  .el-input {
    width: 170px;
    height: 36px;
  }
  /deep/ .el-input__inner:focus {
    border: 1px #ef7c1b solid;
  }
  /deep/ .el-input__inner:hover {
    border: 1px #ef7c1b solid;
  }
}

.packageItem {
  margin-left: 20px;

  margin-bottom: 10px;

  div:nth-child(2) {
    /deep/ .el-input__inner,
    .el-input {
      width: 500px;
      height: 36px;
    }
  }
  div:nth-child(3) {
    display: flex;
    /deep/ .el-input__inner,
    .el-input {
      width: 150px;
      height: 36px;
    }
    /deep/ .el-input {
      margin-left: 20px;
    }
    /deep/ .el-input:nth-child(1) {
      margin-left: 0px;
    }
  }
}

.remarks {
  margin-top: 60px;
  display: flex;
  margin-bottom: 60px;
  margin-left: 6px;
  color: #3a3a3a;
  /deep/ .el-textarea__inner,
  .el-textarea {
    width: 80%;
  }
}
.packingServe {
  margin: 10px 10px 10px 100px;
  button {
    margin-bottom: 40px;
  }
}

.contentB {
  width: 64%;
}

.hide .el-upload--picture-card {
  display: none;
}
.topbox {
  margin-top: 20px;

  ::v-deep {
    .el-checkbox-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
    .el-checkbox {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      min-width: 25%;
      width: 300px;
    }

    .el-checkbox__label {
      flex: 1;
      display: flex;
      align-items: center;
      //  justify-content: space-between;
    }
  }
}
.packageBox {
  display: flex;
  margin-top: 20px;
  width: 100%;
  .shangchunbox {
    margin-left: 20px !important;
    color: #3a3a3a;
    font-size: 14px;
  }
  .butboxs {
    margin-left: 20px;
  }
}

.displayFlex {
  display: flex;
  align-items: center;
}

.checkboxdiv {
  display: flex;
  align-items: center;
  margin: 0 50px 20px 10px;
}

.countBox {
  margin: 0 10px;
}
</style>
